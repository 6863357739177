import type { MetaFunction } from "@remix-run/node";
import { Link } from "@remix-run/react";
import { Button } from "~/components/ui/button";
import { PageShell } from "~/components/layouts/page-shell";
import { LightningBoltIcon } from "@radix-ui/react-icons";
import { IntegrationSetupCard } from "~/components/integration-setup-card";
import { Images } from "~/lib/images";
import { FaShieldAlt, FaExpandArrowsAlt } from "react-icons/fa";
import { IntegrationModel } from "~/lib/data";

export const meta: MetaFunction = () => {
  return [
    { title: "QuickBridge: Seamless Data Integration Platform" },
    {
      name: "description",
      content:
        "Streamline your business workflows with QuickBridge's powerful integrations. Connect your tools effortlessly and boost productivity.",
    },
  ];
};

const featuredIntegrations = [
  {
    id: "salesforce-hubspot",
    source: {
      name: "QuickBooks Online",
      image: Images.Integration.QuickBooksOnline,
    },
    target: { name: "EFRIS", image: Images.Integration.EFRIS },
    description: "Sync customer data between QuickBooks Online and EFRIS",
    category: "Tax",
  },
  {
    id: "shopify-quickbooks",
    source: {
      name: "QuickBooks Desktop",
      image: Images.Integration.QuickBooksDesktop,
    },
    target: { name: "EFRIS", image: Images.Integration.EFRIS },
    description:
      "Automate order and financial data transfer from QuickBooks Desktop to EFRIS.",
    category: "Tax",
  },
  {
    id: "mailchimp-stripe",
    source: { name: "Insta", image: Images.Integration.InstaHealth },
    target: {
      name: "QuickBooks Desktop",
      image: Images.Integration.QuickBooksDesktop,
    },
    description: "Connect your Insta Health data to QuickBooks Desktop.",
    category: "Healthcare",
  },
];

export default function Index() {

  return (
    <PageShell className="bg-white dark:bg-zinc-900">
      <div className="absolute inset-0 -z-10 h-full w-full opacity-[0.05] dark:opacity-[0.02]"
           style={{
             backgroundImage: `url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23000000' fill-opacity='1'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`
           }}
      />

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16 md:py-24">
        <header className="text-center mb-24">
          <div className="inline-block mb-4 px-3 py-1 bg-slate-100 dark:bg-slate-800 rounded-full text-sm font-medium">
            <span className="text-slate-600 dark:text-slate-400">
              Version 1.0 • Public Beta
            </span>
          </div>
          <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold tracking-tight text-slate-900 dark:text-white mb-6">
            Seamless data integration platform
            <br />
            <span className="text-emerald-500 dark:text-emerald-400">
              to automate your business
            </span>
          </h1>
          <p className="text-lg text-slate-600 dark:text-slate-400 max-w-2xl mx-auto mb-8">
            Integrate your business tools seamlessly.
            <br />
            From accounting to e-invoicing, all in one place.
          </p>
        </header>

        <section className="mb-32">
          <div className="flex items-center justify-between mb-8">
            <h2 className="text-2xl font-semibold text-slate-900 dark:text-white">
              Popular Integrations
            </h2>
            <Link to="/integrations">
              <Button
                variant="outline"
                className="border-slate-200 text-slate-600 hover:bg-slate-50 dark:border-slate-700 dark:text-slate-400 dark:hover:bg-slate-800"
              >
                View All
              </Button>
            </Link>
          </div>
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
            {featuredIntegrations.map((integration) => (
              <IntegrationSetupCard key={integration.id} integration={integration as IntegrationModel} />
            ))}
          </div>
        </section>

        <section className="mb-32">
          <h2 className="text-2xl font-semibold text-slate-900 dark:text-white text-center mb-12">
            Why Choose QuickBridge?
          </h2>
          <div className="grid md:grid-cols-3 gap-6">
            {[
              {
                icon: <LightningBoltIcon className="w-8 h-8" />,
                title: "Lightning-Fast Setup",
                description: "Connect your tools in minutes with our intuitive interface."
              },
              {
                icon: <FaShieldAlt className="w-8 h-8" />,
                title: "Secure & Reliable",
                description: "Your data is protected with enterprise-grade security measures."
              },
              {
                icon: <FaExpandArrowsAlt className="w-8 h-8" />,
                title: "Scalable Solution",
                description: "Grow your business with a platform that scales with you."
              }
            ].map((feature, index) => (
              <div 
                key={index}
                className="bg-white dark:bg-slate-800 rounded-xl border border-slate-200 dark:border-slate-700 p-6 hover:shadow-lg transition-all duration-200"
              >
                <div className="text-slate-600 dark:text-slate-400 mb-4">{feature.icon}</div>
                <h3 className="text-lg font-semibold text-slate-900 dark:text-white mb-2">{feature.title}</h3>
                <p className="text-slate-600 dark:text-slate-400">{feature.description}</p>
              </div>
            ))}
          </div>
        </section>

        <section className="rounded-xl bg-slate-50 dark:bg-slate-800/50 border border-slate-200 dark:border-slate-700">
          <div className="px-8 py-12 text-center">
            <h2 className="text-2xl font-semibold text-slate-900 dark:text-white mb-4">
              Ready to get started?
            </h2>
            <p className="text-slate-600 dark:text-slate-400 mb-8">
              Join thousands of teams already using QuickBridge
            </p>
            <div className="flex justify-center gap-4">
              <Link to="/signup">
                <Button
                  size="lg"
                  className="bg-slate-900 hover:bg-slate-800 text-white dark:bg-white dark:hover:bg-slate-100 dark:text-slate-900"
                >
                  Start free trial
                </Button>
              </Link>
              <Link to="/contact">
                <Button
                  size="lg"
                  variant="outline"
                  className="border-slate-200 text-slate-600 hover:bg-slate-50 dark:border-slate-700 dark:text-slate-400 dark:hover:bg-slate-800"
                >
                  Contact sales
                </Button>
              </Link>
            </div>
          </div>
        </section>
      </div>
    </PageShell>
  );
}
